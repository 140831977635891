/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы APJoinTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('apjoin.tableHeading.id'),
      field: 'id',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.id
    },
    {
      label: $t('apjoin.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('apjoin.tableHeading.description'),
      field: 'description',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.description
      // visibilityDropdownSettings: {
      //   hidden: true
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ apJoinProfilesData, selectedApJoinProfileIds }) {
  return apJoinProfilesData.map(apJoinProfile => {
    const {
      id,
      name,
      description,
      led_enabled,
      mss_size_wan,
      lldp_enabled,
      ntp_server,
      timezone,
      offset_hh,
      offset_mm,
      primary_controller,
      secondary_controller,
      tertiary_controller,
      ssh_enabled,
      ssh_port,
      username,
      stats_enabled,
      stats_report_period,
      log_ip,
      log_facility,
      log_level
    } = apJoinProfile;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: apJoinProfile,
      id,
      name,
      description,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedApJoinProfileIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
