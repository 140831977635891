/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').APJoinProfile_FRONTEND} APJoinProfile_FRONTEND
 * @typedef {import('./types.js').BaseProfile_FRONTEND} BaseProfile_FRONTEND
 * @typedef {import('./types.js').APJoinProfile_BACKEND} APJoinProfile_BACKEND
 * @typedef {import('./types.js').Passwords} Passwords
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter(param => (param.value !== undefined && param.value !== null))
    .map(param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов ap_join_profiles с бэковых типов (APJoinProfile_BACKEND) в фронтовые (APJoinProfile_FRONTEND).
 * Использовать, когда получаем ap_join_profile с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {APJoinProfile_BACKEND} ap_join_profile - APJoinProfile_BACKEND данные для конвертации типов.
 * @returns {APJoinProfile_FRONTEND} - сконвертированные данные с типизацией APJoinProfile_FRONTEND.
 */
function apsProfileDataTypesConverterForFrontend(ap_join_profile) {
  const {
    id,
    name,
    description,
    led_enabled,
    mss_size_wan,
    lldp_enabled,
    ntp_server,
    timezone,
    offset_hh,
    offset_mm,
    primary_controller,
    secondary_controller,
    tertiary_controller,
    ssh_enabled,
    ssh_port,
    username,
    stats_enabled,
    stats_report_period,
    log_facility,
    log_ip,
    log_level
  } = ap_join_profile;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    name: String(name),
    description: String(description),
    led_enabled: Number(led_enabled),
    mss_size_wan: Number(mss_size_wan),
    lldp_enabled: Number(lldp_enabled),
    ntp_server: String(ntp_server),
    timezone: String(timezone),
    offset_hh: Number(offset_hh),
    offset_mm: Number(offset_mm),
    primary_controller: String(primary_controller),
    secondary_controller: String(secondary_controller),
    tertiary_controller: String(tertiary_controller),
    ssh_enabled: Number(ssh_enabled),
    ssh_port: Number(ssh_port),
    username: String(username),
    stats_enabled: Number(stats_enabled),
    stats_report_period: Number(stats_report_period),
    log_facility: String(log_facility),
    log_ip: String(log_ip),
    log_level: String(log_level)
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов ap_join_profile с фронтовых типов (APJoinProfile_FRONTEND) в бэковые (APJoinProfile_BACKEND).
 * Использовать, когда возвращаем ap_join_profile с фронта на бэк.
 *
 * ! поля id, password, secret_password могут быть, могут не быть в зависимости от запроса.
 *
 * @param {BaseProfile_FRONTEND & Partial<Id> & Partial<Passwords>} ap_join_profile - APJoinProfile_FRONTEND данные для конвертации типов.
 * @returns {BaseProfile_BACKEND & Partial<Id> & Partial<Passwords>} - сконвертированные данные с типизацией APJoinProfile_BACKEND.
 */
function apsProfileDataTypesConverterForBackend(ap_join_profile) {
  const {
    id, // может быть, может не быть. опциональный
    name,
    description,
    led_enabled,
    mss_size_wan,
    lldp_enabled,
    ntp_server,
    timezone,
    offset_hh,
    offset_mm,
    primary_controller,
    secondary_controller,
    tertiary_controller,
    ssh_enabled,
    ssh_port,
    username,
    password, // может быть, может не быть. опциональный
    secret_password, // может быть, может не быть. опциональный
    stats_enabled,
    stats_report_period,
    log_facility,
    log_ip,
    log_level
  } = ap_join_profile;

  const convertedTypesToBackendFormat = {
    name: String(name),
    description: String(description),
    led_enabled: String(led_enabled),
    mss_size_wan: String(mss_size_wan),
    lldp_enabled: String(lldp_enabled),
    ntp_server: String(ntp_server),
    timezone: String(timezone),
    offset_hh: String(offset_hh),
    offset_mm: String(offset_mm),
    primary_controller: String(primary_controller),
    secondary_controller: String(secondary_controller),
    tertiary_controller: String(tertiary_controller),
    ssh_enabled: String(ssh_enabled),
    ssh_port: String(ssh_port),
    username: String(username),
    stats_enabled: String(stats_enabled),
    stats_report_period: String(stats_report_period),
    log_facility: String(log_facility),
    log_ip: String(log_ip),
    log_level: String(log_level)
  };

  // может быть, может не быть. опциональный
  if (id !== undefined) {
    convertedTypesToBackendFormat.id = String(id);
  }

  // может быть, может не быть. опциональный
  if (password !== undefined) {
    convertedTypesToBackendFormat.password = String(password);
  }

  // может быть, может не быть. опциональный
  if (secret_password !== undefined) {
    convertedTypesToBackendFormat.secret_password = String(secret_password);
  }

  return convertedTypesToBackendFormat;
}

export {
  getFetcherUrl,
  apsProfileDataTypesConverterForFrontend,
  apsProfileDataTypesConverterForBackend
};
