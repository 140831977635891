import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Общие настройки.
 * @property {FieldConfig} general.profileName
 * @property {FieldConfig} general.profileDescription
 * @property {FieldConfig} general.ledState
 * @property {FieldConfig} general.mssSizeOfWanInterface
 * @property {FieldConfig} general.lldpCdpSupport
 * @property {FieldConfig} general.ipAdressOfNTPServer
 * @property {FieldConfig} general.timezone
 * @property {FieldConfig} general.offsetHH
 * @property {FieldConfig} general.offsetMM
 * @property {FieldConfig} general.primaryController
 * @property {FieldConfig} general.secondaryController
 * @property {FieldConfig} general.tertiaryController
 * @property {Object} management - Настройки управления.
 * @property {FieldConfig} management.sshSupport
 * @property {FieldConfig} management.sshPort
 * @property {FieldConfig} management.username
 * @property {FieldConfig} management.password
 * @property {FieldConfig} management.secretPassword
 * @property {Object} statistics - Настройки статистики.
 * @property {FieldConfig} statistics.sendApStatistic
 * @property {FieldConfig} statistics.apStatiscticReportPeriod
 * @property {Object} syslog - Настройки системного журнала.
 * @property {FieldConfig} syslog.facilityValue
 * @property {FieldConfig} syslog.ipAdressOfRemoteServerForSyslogMessages
 * @property {FieldConfig} syslog.logLevel
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    profileName: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    profileDescription: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false
    },
    ledState: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    mssSizeOfWanInterface: {
      isDisabled: false,
      defaultValue: 1250,
      isRequired: true
    },
    lldpCdpSupport: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    ipAdressOfNTPServer: {
      isDisabled: false,
      defaultValue: '0.0.0.0',
      isRequired: true
    },
    timezone: {
      isDisabled: false,
      defaultValue: 'not_configured',
      isRequired: true
    },
    offsetHH: {
      isDisabled: false,
      defaultValue: 0,
      isRequired: true
    },
    offsetMM: {
      isDisabled: false,
      defaultValue: 0,
      isRequired: true
    },
    primaryController: {
      isDisabled: false,
      defaultValue: '0.0.0.0',
      isRequired: true
    },
    secondaryController: {
      isDisabled: false,
      defaultValue: '0.0.0.0',
      isRequired: true
    },
    tertiaryController: {
      isDisabled: false,
      defaultValue: '0.0.0.0',
      isRequired: true
    }
  },
  management: {
    sshSupport: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    sshPort: {
      isDisabled: false,
      defaultValue: 22,
      isRequired: true
    },
    username: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    password: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    secretPassword: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    }
  },
  statistics: {
    sendApStatistic: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    apStatiscticReportPeriod: {
      isDisabled: false,
      defaultValue: 60,
      isRequired: true
    }
  },
  syslog: {
    facilityValue: {
      isDisabled: false,
      defaultValue: 'none',
      isRequired: true
    },
    ipAdressOfRemoteServerForSyslogMessages: {
      isDisabled: false,
      defaultValue: '0.0.0.0',
      isRequired: true
    },
    logLevel: {
      isDisabled: false,
      defaultValue: 'notice',
      isRequired: true
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */
function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    if (value instanceof Object && key in target) {
      result[key] = mergeDeep(target[key], value);
    } else {
      result[key] = value;
    }
  });
  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} label - Метка для группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @param {Object} [params.initialValues.general] - Начальные значения и настройки для общей вкладки.
 * @param {Object} [params.initialValues.management] - Начальные значения и настройки для вкладки управления.
 * @param {Object} [params.initialValues.statistics] - Начальные значения и настройки для вкладки статистики.
 * @param {Object} [params.initialValues.syslog] - Начальные значения и настройки для вкладки системного журнала.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const {
    i18nInstance,
    initialValues = {}
  } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: {
      profileName,
      profileDescription,
      ledState,
      mssSizeOfWanInterface,
      lldpCdpSupport,
      ipAdressOfNTPServer,
      timezone,
      offsetHH,
      offsetMM,
      primaryController,
      secondaryController,
      tertiaryController
    },
    management: {
      sshSupport,
      sshPort,
      username,
      password,
      secretPassword
    },
    statistics: {
      sendApStatistic,
      apStatiscticReportPeriod
    },
    syslog: {
      facilityValue,
      ipAdressOfRemoteServerForSyslogMessages,
      logLevel
    }
  } = mergedValues;

  /**
  * @type {TabConfig[]}
  */
  const formConfig = [
    {
      id: uuidv4(),
      label: $t('apjoin.tabLabel.general'),
      name: 'general',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.profileName'),
              name: 'general.profileName',
              inputType: 'text',
              validateRules: `${profileName.isRequired ? 'required|' : ''}uniqueProfileName|profileNameMask|max:120`,
              isDisabled: profileName.isDisabled,
              placeholder: '',
              defaultValue: profileName.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.profileDescription'),
              name: 'general.profileDescription',
              inputType: 'text',
              validateRules: `${profileDescription.isRequired ? 'required|' : ''}profileDescriptionMask|max:120`,
              isDisabled: profileDescription.isDisabled,
              placeholder: '',
              defaultValue: profileDescription.defaultValue
            }
          ]
        },
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.ledState'),
              name: 'general.ledState',
              inputType: 'checkbox',
              validateRules: `${ledState.isRequired ? 'required' : ''}`,
              isDisabled: ledState.isDisabled,
              placeholder: '',
              defaultValue: ledState.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.mssSizeOfWanInterface'),
              name: 'general.mssSizeOfWanInterface',
              inputType: 'number',
              validateRules: `${mssSizeOfWanInterface.isRequired ? 'required|' : ''}integer|between:536,1363`,
              isDisabled: mssSizeOfWanInterface.isDisabled,
              placeholder: '',
              defaultValue: mssSizeOfWanInterface.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.lldpCdpSupport'),
              name: 'general.lldpCdpSupport',
              inputType: 'checkbox',
              validateRules: `${lldpCdpSupport.isRequired ? 'required' : ''}`,
              isDisabled: lldpCdpSupport.isDisabled,
              placeholder: '',
              defaultValue: lldpCdpSupport.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.ipAdressOfNTPServer'),
              name: 'general.ipAdressOfNTPServer',
              inputType: 'text',
              validateRules: `${ipAdressOfNTPServer.isRequired ? 'required|' : ''}ipv4`,
              isDisabled: ipAdressOfNTPServer.isDisabled,
              placeholder: 'x.x.x.x',
              defaultValue: ipAdressOfNTPServer.defaultValue
            }
          ]
        },
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.timezone'),
              name: 'general.timezone',
              inputType: 'select',
              validateRules: `${timezone.isRequired ? 'required' : ''}`,
              isDisabled: timezone.isDisabled,
              placeholder: '',
              defaultValue: timezone.defaultValue,
              selectOptions: [
                { display: $t('apjoin.inputLabel.timezone.selectOption.notConfigured'), data: 'not_configured' },
                { display: $t('apjoin.inputLabel.timezone.selectOption.wlc'), data: 'wlc' },
                { display: $t('apjoin.inputLabel.timezone.selectOption.delta'), data: 'delta' }
              ]
            },
            // general.offsetHH зависит от значения general.timezone
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.offsetHH'),
              name: 'general.offsetHH',
              inputType: 'number',
              validateRules: `${offsetHH.isRequired ? 'required|' : ''}integer|between:-12,14`,
              isDisabled: offsetHH.isDisabled,
              placeholder: '',
              defaultValue: offsetHH.defaultValue
            },
            // general.offsetHH зависит от значения general.timezone
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.offsetMM'),
              name: 'general.offsetMM',
              inputType: 'number',
              validateRules: `${offsetMM.isRequired ? 'required|' : ''}integer|between:0,59`,
              isDisabled: offsetMM.isDisabled,
              placeholder: '',
              defaultValue: offsetMM.defaultValue
            }
          ]
        },
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.primaryController'),
              name: 'general.primaryController',
              inputType: 'text',
              validateRules: `${primaryController.isRequired ? 'required|' : ''}ipv4`,
              isDisabled: primaryController.isDisabled,
              placeholder: 'x.x.x.x',
              defaultValue: primaryController.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.secondaryController'),
              name: 'general.secondaryController',
              inputType: 'text',
              validateRules: `${secondaryController.isRequired ? 'required|' : ''}ipv4`,
              isDisabled: secondaryController.isDisabled,
              placeholder: 'x.x.x.x',
              defaultValue: secondaryController.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.tertiaryController'),
              name: 'general.tertiaryController',
              inputType: 'text',
              validateRules: `${tertiaryController.isRequired ? 'required|' : ''}ipv4`,
              isDisabled: tertiaryController.isDisabled,
              placeholder: 'x.x.x.x',
              defaultValue: tertiaryController.defaultValue
            }
          ]
        }
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('apjoin.tabLabel.management'),
      name: 'management',
      content: [
        {
          label: $t('apjoin.tabText.sshLabel'), // заголовок вверху страницы
          inputs: []
        },
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.sshSupport'),
              name: 'management.sshSupport',
              inputType: 'checkbox',
              validateRules: `${sshSupport.isRequired ? 'required' : ''}`,
              isDisabled: sshSupport.isDisabled,
              placeholder: '',
              defaultValue: sshSupport.defaultValue
            },
            // management.SshPort зависит от значения management.SshSupport
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.sshPort'),
              name: 'management.sshPort',
              inputType: 'number',
              validateRules: `${sshPort.isRequired ? 'required|' : ''}integer|between:0,65535`,
              isDisabled: sshPort.isDisabled,
              placeholder: '',
              defaultValue: sshPort.defaultValue
            },
            // management.Username зависит от значения management.SshSupport
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.username'),
              name: 'management.username',
              inputType: 'text',
              validateRules: `${username.isRequired ? 'required|' : ''}managementUsernameMask|max:30`,
              isDisabled: username.isDisabled,
              placeholder: '',
              defaultValue: username.defaultValue
            },
            // management.Password зависит от значения management.SshSupport
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.password'),
              name: 'management.password',
              inputType: 'password',
              validateRules: `${password.isRequired ? 'required|' : ''}managementPasswordMask|min:8|max:120`,
              isDisabled: password.isDisabled,
              placeholder: '',
              defaultValue: password.defaultValue
            },
            // management.SecretPassword зависит от значения management.SshSupport
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.secretPassword'),
              name: 'management.secretPassword',
              inputType: 'password',
              validateRules: `${secretPassword.isRequired ? 'required|' : ''}managementPasswordMask|min:8|max:120`,
              isDisabled: secretPassword.isDisabled,
              placeholder: '',
              defaultValue: secretPassword.defaultValue
            }
          ]
        }
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('apjoin.tabLabel.statistics'),
      name: 'statistics',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.sendApStatistic'),
              name: 'statistics.sendApStatistic',
              inputType: 'checkbox',
              validateRules: `${sendApStatistic.isRequired ? 'required' : ''}`,
              isDisabled: sendApStatistic.isDisabled,
              placeholder: '',
              defaultValue: sendApStatistic.defaultValue
            },
            // statistics.apStatiscticReportPeriod зависит от значения statistics.sendApStatistic
            // логика этого в APProfileFormTabContent
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.apStatiscticReportPeriod'),
              name: 'statistics.apStatiscticReportPeriod',
              inputType: 'number',
              validateRules: `${apStatiscticReportPeriod.isRequired ? 'required|' : ''}integer|between:60,3600`,
              isDisabled: apStatiscticReportPeriod.isDisabled,
              placeholder: '60 - 3600',
              defaultValue: apStatiscticReportPeriod.defaultValue
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      label: $t('apjoin.tabLabel.syslog'),
      name: 'syslog',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.facilityValue'),
              name: 'syslog.facilityValue',
              inputType: 'select',
              validateRules: `${facilityValue.isRequired ? 'required' : ''}`,
              isDisabled: facilityValue.isDisabled,
              placeholder: '',
              defaultValue: facilityValue.defaultValue,
              selectOptions: [
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.none'), data: 'none' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.hostapd'), data: 'hostapd' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.kern'), data: 'kern' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.logread'), data: 'logread' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.cron'), data: 'cron' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.authpriv'), data: 'authpriv' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.ntp'), data: 'ntp' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.cpeagent'), data: 'cpeagent' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.daemon'), data: 'daemon' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.dhcpoptinj'), data: 'dhcpoptinj' }
              ]
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.ipAdressOfRemoteServerForSyslogMessages'),
              name: 'syslog.ipAdressOfRemoteServerForSyslogMessages',
              inputType: 'text',
              validateRules: `${ipAdressOfRemoteServerForSyslogMessages.isRequired ? 'required|' : ''}ipv4`,
              isDisabled: ipAdressOfRemoteServerForSyslogMessages.isDisabled,
              placeholder: 'x.x.x.x',
              defaultValue: ipAdressOfRemoteServerForSyslogMessages.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('apjoin.inputLabel.logLevel'),
              name: 'syslog.logLevel',
              inputType: 'select',
              validateRules: `${logLevel.isRequired ? 'required' : ''}`,
              isDisabled: logLevel.isDisabled,
              placeholder: '',
              defaultValue: logLevel.defaultValue,
              selectOptions: [
                { display: $t('apjoin.inputLabel.logLevel.selectOption.notice'), data: 'notice' },
                { display: $t('apjoin.inputLabel.logLevel.selectOption.informational'), data: 'informational' },
                { display: $t('apjoin.inputLabel.facilityValue.selectOption.debug'), data: 'debug' }
              ]
            }
          ]
        }
      ]
    }
  ];

  return formConfig;
}

export default generateFormConfig;
