<template>
  <APProfileForm
    :formConfig="formConfig"
    :forbiddenProfileNames="forbiddenProfileNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </APProfileForm>
</template>

<script>
/* eslint-disable camelcase */

/**
 * компонент настроенной формы APProfileForm через generateFormConfig для клонирования существующего профия ap join.
 * ! Компонент локальный, используется только в CloneAPProfileModal.vue
 * @component
 */

import {
  APProfileForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CloneAPProfileForm',
  components: {
    APProfileForm
  },
  props: {
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    },
    apProfileForCloning: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля формы
    const {
      name,
      description,
      led_enabled, // значения 0 или 1 или 2. 0 - неопределенно, 1 - выкл, 2 - вкл,
      mss_size_wan,
      lldp_enabled, // Может принимать значения 0 или 1 или 2. 0 - неопределенно, 1 - выкл, 2 - вкл.
      ntp_server,
      timezone,
      offset_hh,
      offset_mm,
      primary_controller,
      secondary_controller,
      tertiary_controller,
      ssh_enabled, // Может принимать значения 0 или 1 или 2. 0 - неопределённо, 1 - выкл, 2 - вкл.
      ssh_port,
      username,
      stats_enabled, // Может принимать значения 0 или 1 или 2. 0 - не определено, 1 - выкл, 2 - вкл.
      stats_report_period,
      log_ip,
      log_facility,
      log_level
    } = this.apProfileForCloning;

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          // profileName: { isDisabled: false, defaultValue: '' },
          profileDescription: {
            defaultValue: description
          },
          ledState: {
            defaultValue: led_enabled === 2
          },
          mssSizeOfWanInterface: {
            defaultValue: mss_size_wan
          },
          lldpCdpSupport: {
            defaultValue: lldp_enabled === 2
          },
          ipAdressOfNTPServer: {
            defaultValue: ntp_server
          },
          timezone: {
            defaultValue: timezone
          },
          offsetHH: {
            defaultValue: offset_hh
          },
          offsetMM: {
            defaultValue: offset_mm
          },
          primaryController: {
            defaultValue: primary_controller
          },
          secondaryController: {
            defaultValue: secondary_controller
          },
          tertiaryController: {
            defaultValue: tertiary_controller
          }
        },
        management: {
          sshSupport: {
            defaultValue: ssh_enabled === 2
          },
          sshPort: {
            defaultValue: ssh_port
          },
          username: {
            defaultValue: username
          }
          // password: {
          //   isDisabled: false,
          //   defaultValue: ''
          // },
          // secretPassword: {
          //   isDisabled: false,
          //   defaultValue: ''
          // }
        },
        statistics: {
          sendApStatistic: {
            isDisabled: false,
            defaultValue: stats_enabled === 2
          },
          apStatiscticReportPeriod: {
            defaultValue: stats_report_period
          }
        },
        syslog: {
          facilityValue: {
            defaultValue: log_facility
          },
          ipAdressOfRemoteServerForSyslogMessages: {
            defaultValue: log_ip
          },
          logLevel: {
            defaultValue: log_level
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
