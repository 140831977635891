<template>
  <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <!-- содержимое каждого таба -->
      <APProfileFormTabContent
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит из модалки -->
    <template v-slot:control-buttons>
      <slot name="control-buttons"></slot>
    </template>
  </FormWithTabs>
</template>

<script>
/**
 * локальный компонент формы с табами для форм в странице ap join page.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import {
  APProfileFormTabContent
} from './components';

import {
  getAPProfileFormValidators
} from './validators';

export default {
  name: 'APProfileForm',
  components: {
    APProfileFormTabContent
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    setAPPProfileFormValidators() {
      const validators = getAPProfileFormValidators(this.$i18n);

      const uniqueProfileName = validators.uniqueProfileName(this.forbiddenProfileNames);
      const profileNameMask = validators.profileNameMask();
      const profileDescriptionMask = validators.profileDescriptionMask();
      const managementUsernameMask = validators.managementUsernameMask();
      const managementPasswordMask = validators.managementPasswordMask();

      const validator = this.$validator;

      validator.extend('uniqueProfileName', uniqueProfileName);
      validator.extend('profileNameMask', profileNameMask);
      validator.extend('profileDescriptionMask', profileDescriptionMask);
      validator.extend('managementUsernameMask', managementUsernameMask);
      validator.extend('managementPasswordMask', managementPasswordMask);
    }
  },
  mounted() {
    this.setAPPProfileFormValidators();
  }
};
</script>

<style lang="css" scoped>
</style>
