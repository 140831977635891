<template>
  <section>
    <div class="row" style="position: relative">
      <loader-spinner v-if="isCompactWlansWithoutClientsLoading || isCompactCpesLoading" centered></loader-spinner>
      <div class="col-lg-12">
        <div class="form-group mt-1">
          <label class="required-mark" for="name">{{ $t('general.name') }}</label>
          <input
            type="text"
            name="name"
            id="name"
            :disabled="isDisable"
            :placeholder="$t('GuestControl.enterName')"
            class="form-control input"
            v-validate="'required|max:32'"
            :class="{ 'is-danger': errors.has('name') }"
            v-model="redirectRuleData.name"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('name') }"
            v-show="errors.has('name')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>

        <!--        redirect type-->
        <div class="form-group" >
          <WSelect
            @input="redirectTypeSelectorHandler"
            :required="true"
            v-model="redirectRuleData.redirect_type"
            :options="redirectTypesOptionsForSelector"
            :label="$t('GuestControl.redirectType')"
            :errorText="errors.first('redirect_type')"
            v-validate="'required'"
            :disabled="isDisable"
            customClass="form-control"
            id="redirect_type"
            :data-vv-as="$t('GuestControl.redirectType')"
            name="redirect_type"

          />
        </div>

        <div class="form-group" v-if="areLocationsVisible && (isStaticRedirectType || isDynamicRedirectType)">
          <label for="location">{{ $t('general.location') }}</label>
          <select
            v-model="redirectRuleData.base_location"
            name="location"
            id="location"
            :disabled="isDisable"
            :class="{ 'select-disabled': isDisable }"
            class="form-control"
          >
            <option v-for="location in locationsList" :value="location.id" :key="location.id">
              {{ location.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <div v-if="isStaticRedirectType">
          <div v-if="isExpert">
            <div class="redirect-url-input">
              <div class="d-flex justify-content-between align-items-baseline">
                <label for="redirect_url" class="redirect-url-heading">{{ $t('GuestControl.redirectUrl') }}</label>
                <WButton link
                  :customClass="{ 'mr-2': errors.has('redirect_url') }"
                  @click="isExpert = !isExpert"
                >
                  {{ isExpert ? $t('GuestControl.expertHide') : $t('GuestControl.expertShow') }}
                </WButton>
              </div>
              <textarea
                @change="changeUrlInExpertModeHandler"
                id="redirect_url"
                name="redirect_url"
                class="form-control redirect-url-preview form-group input"
                :class="{ 'is-danger': errors.has('redirect_url') }"
                placeholder="http://www.example.com/?mac={CLIENT_MAC}&wlan={WLAN_SSID}&client_ip={CLIENT_IP}&cpe_id={AP_UUID}&wlan_id={WLAN_UUID}"
                rows="5"
                data-vv-as="redirect url"
                :disabled="isDisable"
                v-validate="'required'"
                v-model="redirectRuleData.redirect_url"
              />
            </div>
            <span
              v-tooltip.top-center="{ content: errors.first('redirect_url') }"
              v-show="errors.has('redirect_url')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
            <div class="pb-h">
              <small class="tooltip-text text-primary high" @click="isTipsToFillShow = !isTipsToFillShow">
                <span v-if="!isTipsToFillShow">{{ $t('GuestControl.howTo') }}</span>
                <span v-else>{{ $t('general.close').toLowerCase() }}</span>
              </small>
              <transition name="interface">
                <div v-if="isTipsToFillShow" class="mt-h">
                  <p class="how-to-fill">
                    <strong>
                      example
                      <br />
                    </strong>
                    <span class="font-italic">
                      http://www.example.com/?mac={CLIENT_MAC}&wlan={WLAN_SSID}&client_ip={CLIENT_IP}&cpe_id={AP_UUID}&wlan_id={WLAN_UUID}&switch_url=http://10.10.10.12:1234&redirect_url=http://google.com/
                    </span>
                  </p>
                  <ul>
                    <li>
                      <mark>http://</mark>
                      is required!
                    </li>
                    <li>
                      Fill in this form using
                      <mark><i>key={PARAM_NAME}</i></mark>
                      template. A relevant parameter will be used instead of
                      <mark><i>{PARAM_NAME}</i></mark>
                      implication.
                    </li>
                    <li>
                      PARAM_NAME:
                      <strong>CLIENT_MAC, CLIENT_IP, WLAN_SSID, WLAN_UUID, BSSID, AP_UUID, PORTAL_ID</strong>
                    </li>
                    <li>
                      Key
                      <mark><i>SWITCH_URL</i></mark>
                      : Platform address where client got switched
                    </li>
                    <li>
                      Key
                      <mark><i>REDIRECT_URL</i></mark>
                      : Address where client got transferred after successful authorization
                    </li>
                  </ul>
                  <!--<p>The use of the environment variable is also possible. </p>-->
                </div>
              </transition>
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-content-between align-items-baseline">
              <p class="redirect-url-heading">{{ $t('GuestControl.redirectUrl') }}</p>
              <WButton link @click="isExpert = !isExpert">
                {{ isExpert ? $t('GuestControl.expertHide') : $t('GuestControl.expertShow') }}
              </WButton>
            </div>

            <div class="form-group">
              <label class="required-mark" for="wizard-domain">{{ $t('GuestControl.domain') }}</label>
              <input
                type="text"
                name="wizard-domain"
                id="wizard-domain"
                aria-describedby="caption-domain"
                placeholder="www.example.com"
                class="form-control input"
                v-validate="'required'"
                data-vv-as="domain"
                :disabled="isDisable"
                :class="{ 'is-danger': errors.has('wizard-domain') }"
                v-model="redirectURLConfig.domain"
              />
              <small id="caption-domain" class="text-muted">{{ $t('GuestControl.domainCaption') }}</small>
              <span
                v-tooltip.top-center="{ content: errors.first('wizard-domain') }"
                v-show="errors.has('wizard-domain')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>

            <div class="form-group">
              <label for="wizard-cpes">{{ $t('general.cpe') }}</label>
              <select class="form-control" v-model="redirectURLConfig.cpe_id" id="wizard-cpes" :disabled="isDisable">
                <option :value="''">{{ $t('GuestControl.allCpes') }}</option>
                <option v-for="cpe in cpesList" v-if="cpesList" :value="cpe.id" :key="cpe.id">
                  {{ cpe.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="wizard-wlan">{{ $t('general.wlan') }}</label>
              <select
                class="form-control"
                v-model="redirectURLConfig.wlan_id"
                id="wizard-wlan"
                aria-describedby="caption-wlan"
                :disabled="isDisable"
              >
                <option :value="''">{{ $t('GuestControl.allWlans') }}</option>
                <option v-for="wlan in wlansList" v-if="wlansList" :value="wlan.id" :key="wlan.id">
                  {{ wlan.ssid }}
                </option>
              </select>
              <small id="caption-wlan" class="text-muted">{{ $t('GuestControl.wlanCaption') }}</small>
            </div>

             <div class="form-group" v-if="areLocationsVisible">
              <label for="wizard-targeting-location">{{ $t('GuestControl.targetingLocation') }}</label>
              <select
                v-model="redirectURLConfig.loc_id"
                name="wizard-targeting-location"
                id="wizard-targeting-location"
                :disabled="isDisable"
                :class="{ 'select-disabled': isDisable }"
                class="form-control"
              >
                <option v-for="location in locationsList" :value="location.loc_id" :key="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="required-mark" for="wizard-switch">{{ $t('GuestControl.switchURL') }}</label>
              <input
                type="text"
                name="wizard-switch"
                id="wizard-switch"
                aria-describedby="caption-switch"
                placeholder="www.example.com"
                class="form-control input"
                v-validate="'required'"
                data-vv-as="switch url"
                :disabled="isDisable"
                :class="{ 'is-danger': errors.has('wizard-switch') }"
                v-model="redirectURLConfig.switch_url"
              />
              <small id="caption-switch" class="text-muted">{{ $t('GuestControl.switchURLCaption') }}</small>
              <span
                v-tooltip.top-center="{ content: errors.first('wizard-switch') }"
                v-show="errors.has('wizard-switch')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>

            <div class="form-group">
              <label for="wizard-redirect">{{ $t('GuestControl.redirectURL') }}</label>
              <input
                type="text"
                name="wizard-redirect"
                id="wizard-redirect"
                aria-describedby="caption-redirect"
                placeholder="https://www.example.com/path/"
                class="form-control input"
                :disabled="isDisable"
                v-model="redirectURLConfig.redirect_url"
              />
              <small id="caption-redirect" class="text-muted">{{ $t('GuestControl.redirectURLCaption') }}</small>
            </div>
          </div>
        </div>

          <div class="form-group" v-if="isStaticRedirectType || isDynamicRedirectType ">
            <Switch-component
              v-model="redirectRuleData.no_masquerade"
              :disabled="isDisable"
              :label="$t('GuestControl.noMasquerade')"
              :id="'no-masquerade'"
            />
          </div>
          <div v-if="isStaticRedirectType || isDynamicRedirectType">
          <h6 class="mt-1">{{ $t('GuestControl.PreAuthACLConfig') }}</h6>
          <small  class="text-muted">{{ $t('GuestControl.PreAuthACLConfigHint') }}</small>
          <div class="url-input-group mt-1 mb-0 d-flex flex-row justify-content-start align-items-start">
            <div class="form-group mr-h mb-0 mt-0">
              <label class="required-mark" for="url_ip">{{ $t('GuestControl.ipAddress') }}</label>
              <input
                type="text"
                name="url_ip"
                class="form-control input"
                placeholder="X.X.X.X"
                id="url_ip"
                :disabled="isDisable"
                v-validate="!newUrlDomain ? 'ipv4' : 'required|ipv4'"
                data-vv-validate-on="input"
                @keyup.13="addUrl"
                v-model="newUrlIp"
                :class="{ 'is-danger': errors.has('url_ip') || (!newUrlIp && newUrlDomain) }"
              />
              <small>{{ $t('general.ipFieldHint') }}</small>
              <span
                v-tooltip.top-center="{ content: errors.first('url_ip') }"
                v-if="errors.has('url_ip')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
              <span
                v-tooltip.top-center="{ content: `${$t('GuestControl.walledGardenIPTooltip')}` }"
                class="help is-danger error_tooltip text-info"
                v-if="!errors.has('url_ip') && redirectRuleData.redirect_url && !redirectRuleData.url_list.length"
              >
                <i class="fa fa-warning"></i>
              </span>
            </div>
            <div class="form-group mr-h mb-0 d-flex flex-row justify-content-start align-items-end">
              <div class="mr-h">
                <label for="url_domain">{{ $t('GuestControl.domainName') }}</label>
                <input
                  type="text"
                  name="url_domain"
                  id="url_domain"
                  class="form-control input"
                  placeholder="www.example.com"
                  :disabled="isDisable"
                  v-validate="''"
                  data-vv-validate-on="input"
                  @keyup.13="addUrl"
                  v-model="newUrlDomain"
                  :class="{ 'is-danger': errors.has('url_domain') }"
                />
              </div>
              <div>

              </div>
              <div class="">
                <WButton success
                         customClass="mb-0"
                         @click="addUrl"
                         :disabled="this.newUrlIp == '' || errors.has('url_ip') || isDisable"
                >
                  {{ $t('general.add') }}
                </WButton>
              </div>
            </div>

          </div>
          <div class="items_list mb-q mt-h">
            <small
              v-for="(url, index) in redirectRuleData.url_list"
              :key="index"
              @click="removeUrl"
              :data-index="index"
              :class="{ 'item-for-delete': !isDisable }"
              class="ml-h"
            >
              {{ url.ip }}
              <span v-if="url.domain_name">&nbsp;—&nbsp;{{ url.domain_name }}</span>
              <i class="fa fa-remove text-danger" v-if="!isDisable"></i>
            </small>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import commonService from '../../services/commonService';
import wlanService from '../../services/wlanService';
import cpeService from '../../services/cpeService';
import SwitchComponent from '../Universal/Switch-component.vue';
import helpers from '../../helpers';
// import SelectComponent from '../Universal/select/select-component.vue';
// import locationService from '../../services/locationService';


import { redirectMixin, defineRedirectTypeMixin, redirectTypeSelectorMixin } from '@/views/GuestControl/mixins';

const STANDARD_PARAMS_NAMES = ['mac', 'wlan', 'client_ip', 'cpe_id', 'wlan_id', 'switch_url', 'redirect_url', 'loc_id']


export default {
  name: 'GeneralSettingsForEasyInstallWizard',
  components: { SwitchComponent },
  mixins: [redirectMixin, defineRedirectTypeMixin, redirectTypeSelectorMixin],
  inject: ['$validator'],
  props: {
    redirectRuleData: {
      type: Object
    },
    isWizard: {
      type: Boolean
    },
    isDisable: {
      type: Boolean
    },
    isEditMode: {
      // тут не используется, но нужен для совместимости по интерфейсу с src/components/GuestControl/generalSettings.vue
      // используется в общих для этого компонента и src/components/GuestControl/generalSettings.vue миксинах
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customParams: undefined,
      isTipsToFillShow: false,
      isExpert: false,
      warningIP: false,
      newUrlDomain: '',
      newUrlIp: '',
      redirectURLConfig: {
        protocol: 'http',
        domain: '',
        cpe_id: '',
        wlan_id: '',
        switch_url: '',
        redirect_url: '',
        loc_id: ''
      }
    };
  },
  watch: {
    calculatedURL(value) {
      if (!this.isExpert) {
        this.redirectRuleData.redirect_url = value;
      }
    }
  },
  computed: {
    /**
     * У редиректа нет типа
     *
     * @return {boolean}
     */
    isNoRedirectType() {
      return this.isNoRedirectTypeDefine(this.redirectRuleData.redirect_type);
    },
    /**
     * Статический ли тип у редиректа
     *
     * @return {boolean}
     */
    isStaticRedirectType() {
      return this.isStaticRedirectTypeDefine(this.redirectRuleData.redirect_type);
    },
    /**
     * Динамический ли тип у редиректа
     *
     * @return {boolean}
     */
    isDynamicRedirectType() {
      return this.isDynamicRedirectTypeDefine(this.redirectRuleData.redirect_type);
    },
    isCompactCpesLoading() {
      return this.$store.state.loadingCpes;
    },
    isCompactWlansWithoutClientsLoading() {
      return this.$store.state.loadingWlans;
    },
    commonService() {
      return commonService;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    standardParamsNames() {
      return STANDARD_PARAMS_NAMES;
    },
    /**
     * Calculates URL string for the wizard
     * @return {string}
     */
    // calculatedURL() {
    //   const {
    //     protocol, domain, cpe_id, wlan_id, switch_url, redirect_url, loc_id
    //   } = this.redirectURLConfig;
    //   const base = `${protocol}://${domain ? this.trimIfSlash(domain) : ''}`;
    //
    //   const query = Object.entries({
    //     mac: '{CLIENT_MAC}',
    //     wlan: '{WLAN_SSID}',
    //     client_ip: '{CLIENT_IP}',
    //     cpe_id: cpe_id || '{AP_UUID}',
    //     wlan_id: wlan_id || '{WLAN_UUID}',
    //     switch_url: switch_url ? `http://${this.trimIfSlash(switch_url)}:1324/api/authorize` : '',
    //     redirect_url: redirect_url || '',
    //     loc_id: loc_id || ''
    //   })
    //     .filter((entry) => entry[1] !== '') /* <-- Removes keys with empty values */
    //     .map((entry) => entry.join('='))
    //     .join('&');
    //
    //   return `${base}/?${query}`;
    // },
    calculatedURL() {
      const {
        protocol, domain, cpe_id, wlan_id, switch_url, redirect_url, loc_id
      } = this.redirectURLConfig;
      const base = `${protocol}://${domain ? this.trimIfSlash(domain) : ''}`;

      const query = Object.entries({
        mac: '{CLIENT_MAC}',
        wlan: '{WLAN_SSID}',
        client_ip: '{CLIENT_IP}',
        cpe_id: cpe_id || '{AP_UUID}',
        wlan_id: wlan_id || '{WLAN_UUID}',
        switch_url: switch_url ? `http://${this.trimIfSlash(switch_url)}:1324/api/authorize` : '',
        redirect_url: redirect_url || '',
        loc_id: loc_id || ''
      })
        .filter((entry) => entry[1] !== '') /* <-- Removes keys with empty values */
        .map((entry) => entry.join('='))
        .join('&');
      let customParamsAsString = undefined;
      if (this.customParams) {
        customParamsAsString = Object.entries(this.customParams).map((entry) => entry.join('=')).join('&')
        // console.log(customParamsAsString)
      }
      if (customParamsAsString) {
        return `${base}/?${customParamsAsString}&${query}`;
      }
      return `${base}/?${query}`;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    cpesList: {
      get() {
        return this.$store.state.cpesList;
      },
      set() {}
    }
  },
  methods: {

    changeUrlInExpertModeHandler() {
      this.$nextTick(() => {
        this.parseRedirectURL(this.redirectRuleData.redirect_url, false);
      })
    },
    checkAreCustomParamsInRedirectUrl(objectForCheck) {
      const standardParamNames = this.standardParamsNames
      for (const key of Object.keys(objectForCheck)) {
        if (!standardParamNames.includes(key)) {
          return true;
        }
      }
      return false;
    },
    extractCustomParamsFromRedirectUrl(objectForCheck) {
      const result = {};
      const standardParamNames = this.standardParamsNames
      for (const key of Object.keys(objectForCheck)) {
        if (!standardParamNames.includes(key)) {
          result[key] = objectForCheck[key]
        }
      }
      return result;
    },
    // async requestLocations(searchQuery) {
    //   return locationService.requestLocations(searchQuery);
    // },
    addUrl() {
      this.$validator.validateAll({ url_ip: this.newUrlIp, url_domain: this.newUrlDomain }).then((result) => {
        if (result) {
          this.redirectRuleData.url_list.push({
            ip: this.newUrlIp,
            domain_name: this.newUrlDomain
          });
          this.newUrlDomain = '';
          this.newUrlIp = '';
          this.$validator.reset();
        }
      });
    },
    /**
     * Trims last char if it is slash
     */
    trimIfSlash(str) {
      if (str.charAt(str.length - 1) === '/') {
        return str.slice(0, -1);
      }
      return str;
    },
    removeUrl(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.redirectRuleData.url_list.splice(index, 1);
    },
    /**
     * Parses redirect URL string and assigns extracted params to redirectURLConfig
     * @param str
     */
    // parseRedirectURL(str) {
    //   const PROTOCOL_CUTOFF = '://';
    //   const SWITCH_PORT = ':1324';
    //
    //   let base;
    //   let query;
    //   if (str.includes('?')) {
    //     [base, query] = str.split('?');
    //   } else {
    //     /* Handles case when no query is present */
    //     [base, query] = [str, ''];
    //   }
    //   base = this.trimIfSlash(base);
    //
    //   /* Must not be parsed by URL() to prevent string mutation */
    //   const queryObj = query.length ? Object.fromEntries(query.split('&').map((entry) => entry.split('='))) : {};
    //
    //   let { switch_url } = queryObj;
    //   const isSwitchUrlValid = switch_url && switch_url.includes(PROTOCOL_CUTOFF) && switch_url.includes(SWITCH_PORT);
    //   if (isSwitchUrlValid) {
    //     const start = switch_url.indexOf(PROTOCOL_CUTOFF) + PROTOCOL_CUTOFF.length;
    //     const end = switch_url.indexOf(SWITCH_PORT);
    //     switch_url = switch_url.slice(start, end);
    //   } else {
    //     switch_url = '';
    //   }
    //   // console.log(base.slice(base.indexOf(PROTOCOL_CUTOFF) + PROTOCOL_CUTOFF.length) || '')
    //
    //   const parsed = {
    //     protocol: base.slice(0, base.indexOf(PROTOCOL_CUTOFF)) || '',
    //     domain: base.slice(base.indexOf(PROTOCOL_CUTOFF) + PROTOCOL_CUTOFF.length) || '',
    //     cpe_id: queryObj.cpe_id !== '{AP_UUID}' && queryObj.cpe_id ? queryObj.cpe_id : '',
    //     wlan_id: queryObj.wlan_id !== '{WLAN_UUID}' && queryObj.wlan_id ? queryObj.wlan_id : '',
    //     switch_url,
    //     redirect_url: queryObj.redirect_url || '',
    //     loc_id: queryObj.loc_id || ''
    //   };
    //
    //   this.redirectURLConfig = { ...this.redirectURLConfig, ...parsed };
    // }
    parseRedirectURL(str, switchToExpertModeIfCustomParams = false) {
      const PROTOCOL_CUTOFF = '://';
      const SWITCH_PORT = ':1324';

      let base;
      let query;
      if (str.includes('?')) {
        [base, query] = str.split('?');
      } else {
        /* Handles case when no query is present */
        [base, query] = [str, ''];
      }
      base = this.trimIfSlash(base);

      /* Must not be parsed by URL() to prevent string mutation */
      const queryObj = query.length ? Object.fromEntries(query.split('&').map((entry) => entry.split('='))) : {};

      let customParams = undefined;
      this.customParams  = undefined;
      if (this.checkAreCustomParamsInRedirectUrl(JSON.parse(JSON.stringify(queryObj)))){
        if (switchToExpertModeIfCustomParams) {
          this.isExpert = true;
        }
        customParams = this.extractCustomParamsFromRedirectUrl(JSON.parse(JSON.stringify(queryObj)))
        this.customParams = customParams;
      }
      // console.log(customParams);

      let { switch_url } = queryObj;
      const isSwitchUrlValid = switch_url && switch_url.includes(PROTOCOL_CUTOFF) && switch_url.includes(SWITCH_PORT);
      if (isSwitchUrlValid) {
        const start = switch_url.indexOf(PROTOCOL_CUTOFF) + PROTOCOL_CUTOFF.length;
        const end = switch_url.indexOf(SWITCH_PORT);
        switch_url = switch_url.slice(start, end);
      } else {
        switch_url = '';
      }

      const parsed = {
        protocol: base.slice(0, base.indexOf(PROTOCOL_CUTOFF)) || '',
        domain: base.slice(base.indexOf(PROTOCOL_CUTOFF) + PROTOCOL_CUTOFF.length) || '',
        cpe_id: queryObj.cpe_id !== '{AP_UUID}' && queryObj.cpe_id ? queryObj.cpe_id : '',
        wlan_id: queryObj.wlan_id !== '{WLAN_UUID}' && queryObj.wlan_id ? queryObj.wlan_id : '',
        switch_url,
        redirect_url: queryObj.redirect_url || '',
        loc_id: queryObj.loc_id || ''
      };

      this.redirectURLConfig = { ...this.redirectURLConfig, ...parsed };
    }
  },
  created() {
    // wlanService.getAllWlans(this);
    wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this);
    cpeService.getAllCpes(this);

    if (this.redirectRuleData.redirect_url) {
      this.parseRedirectURL(this.redirectRuleData.redirect_url, true);
    }
    // this.redirectURLConfig.domain = this.createdPageUrl;
  }
};
</script>

<style lang="scss" scoped>
.redirect-url-preview {
  resize: none;
  word-break: break-all;
  overflow: auto;
}

.redirect-url-heading {
  margin: 0 0 0.5rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1;
  color: inherit;
}

.items_list {
  overflow-y: unset;
}
.url-input-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  padding-right: 15px;
  padding-left: 15px;

  & .form-group {
    flex-grow: 1;
  }
}
</style>
